import { render, staticRenderFns } from "./record-files-export.vue?vue&type=template&id=63cac2ea&scoped=true"
import script from "./record-files-export.vue?vue&type=script&lang=ts"
export * from "./record-files-export.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63cac2ea",
  null
  
)

export default component.exports