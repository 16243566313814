var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Layout, Dependencies } from "~/core/decorator";
import { State } from "vuex-class";
import DataForm from "~/components/common/data-form.vue";
import NumberRange from "~/components/common/number-range.vue";
import { CaseRecodingService } from '~/services/common-service/case-recoding.service';
var DistributeCaseInfoExport = /** @class */ (function (_super) {
    __extends(DistributeCaseInfoExport, _super);
    function DistributeCaseInfoExport() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.rules = {};
        _this.searchModel = {
            principalId: '',
            caseNumber: '',
            personalName: '',
            certificateNo: '',
            account: '',
            cardNo: '',
            delegationDate: [],
            caseDate: [],
        };
        return _this;
    }
    DistributeCaseInfoExport.prototype.created = function () {
        this.rules = {
            principalId: { required: true, message: '请选择委托方', trigger: 'change' },
        };
    };
    DistributeCaseInfoExport.prototype.downloadRecodingPackage = function () {
        var _this = this;
        if (!this.searchModel.principalId) {
            this.$message.warning('请选择委托方');
            return;
        }
        this.caseRecodingService.downloadRecodingPackage(this.searchModel)
            .subscribe(function (data) {
            _this.$message.success('导出成功,请前往任务盒子查看');
            _this.cleraSearchData();
        }, function (err) { });
    };
    DistributeCaseInfoExport.prototype.cleraSearchData = function () {
        this.searchModel.principalId = '';
        this.searchModel.caseNumber = '';
        this.searchModel.personalName = '';
        this.searchModel.certificateNo = '';
        this.searchModel.account = '';
        this.searchModel.cardNo = '';
        this.searchModel.delegationDate = [];
        this.searchModel.caseDate = [];
    };
    __decorate([
        Dependencies(CaseRecodingService)
    ], DistributeCaseInfoExport.prototype, "caseRecodingService", void 0);
    __decorate([
        State
    ], DistributeCaseInfoExport.prototype, "principalList", void 0);
    DistributeCaseInfoExport = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataForm: DataForm,
                NumberRange: NumberRange
            }
        })
    ], DistributeCaseInfoExport);
    return DistributeCaseInfoExport;
}(Vue));
export default DistributeCaseInfoExport;
